export default async function (accessToken?: string) {
  if (!accessToken) {
    return 0
  }
  const data = await $fetch<{
    scope: string,
    client_id: string,
    expires_in: number
  }>(
    `https://api.line.me/oauth2/v2.1/verify/?access_token=${accessToken}`, {
      method: 'GET'
    })

  return data.expires_in
}
